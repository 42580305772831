<template>
    <div class="modal-wrap">
<!--        <button class="close" @click="close_btn()"></button>-->
        <div class="top">
            <div class="profile":class="{'no-profile': memberData.Profile.length < 1}" :style="`background-image: url(${returnImageUrl(this.memberData)})`"></div>
            <div class="name mentor">{{memberData.mb_name}}</div>
            <div class="job">{{memberData.mb_job}}</div>
        </div>
        <div class="content">
            <div  class="company">
                <div class="title">소속</div>
                <div class="text">{{memberData.mb_belong}}</div>
            </div>
            <div  class="email">
                <div class="title">이메일</div>
                <div class="text">{{memberData.mb_email}}</div>
            </div>
            <div class="introduce">
                <div class="title">자기소개</div><br>
                <div class="text mentor">
                    {{memberData.mb_memo}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import util from "@/mixins/util";
    import {mapState, mapGetters} from "vuex"
    import imageOption from "@/mixins/imageOption";

    export default {
        name: "MemberDetailPageMentorLayout",
        mixins: [imageOption],
        components: {
        },
        props: {
            memberData: {
                type: Object,
                default: () => {return {}},
            },
        },
        data() {
            return {

            }
        },
        beforeRouterEnter() {},
        created() {

        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {

        },
        methods:{
            decodeHtml(html) {
                return util.htmlDecode(html);
            },
            returnImageUrl(member) {
                return member.hasOwnProperty('Profile') && member.Profile.length > 0 ? `${member.Profile[0].org_url}?${this.setImageOptions(88, 88, 'png')}` : '';
            },
        },
        watch: {

        },
    }
</script>

<style scoped>

</style>